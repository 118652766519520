<template>
  <v-app-bar id="app-bar" class="pl-0" absolute app flat height="50px">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      x-small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$t($route.meta.title)" />
    <v-spacer />

    <!-- <dashboard-core-settings /> -->
    <!-- <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template #activator="{ attrs, on }">
        <v-btn min-width="0" text fab small v-bind="attrs" v-on="on">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" flat>
        <v-list-item @click.prevent="changeLang('ro')">
          <v-list-item-title>{{ $t("romanian") }}</v-list-item-title>
        </v-list-item>
        <v-divider class="mb-2 mt-2" />
        <v-list-item @click.prevent="changeLang('en')">
          <v-list-item-title>{{ $t("english") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
    <v-menu
      v-if="appType == 'ebib' && isDatabases"
      bottom
      left
      offset-y
      :max-height="menuMaxHeight"
      :close-on-content-click="false"
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn min-width="0" text fab small v-on="{ ...onMenu, ...on }">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Ajutor!") }}</span>
        </v-tooltip>
      </template>
      <v-card class="mt-0 mb-0" width="360">
        <v-card-title>
          {{ $t("Ajutor!") }}
        </v-card-title>
        <v-card-text class="text-h6">
          <v-divider />
          <v-subheader>{{ $t("Butoane") }}</v-subheader>
          <div>
            <v-btn color="teal" fab text small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            {{ $t("Reîncarcă datele din tabel.") }}
          </div>
          <div>
            <v-btn color="cyan" fab text small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            {{ $t("Adaugă o înregistrare nouă.") }}
          </div>
          <div>
            <v-btn color="brown" fab text small>
              <v-icon>mdi-file-move-outline</v-icon>
            </v-btn>
            {{ $t("Schimbă categoria pentru înregistrăriile selectate.") }}
          </div>
          <div>
            <v-btn color="error" fab text small>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            {{ $t("Șterge înregistrăriile selectate.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            {{ $t("Face căutare după termenii introduși în câmp.") }}
          </div>
          <div>
            <v-btn color="info" fab text small>
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
            {{ $t("Deschide/închide formularul de căutare avansată.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            {{ $t("Deschide/închide formularul de salvare căutări.") }}
          </div>
          <div>
            <v-btn-toggle class="mr-2">
              <v-btn icon small width="35px">
                <v-icon>mdi-view-headline</v-icon>
              </v-btn>
              <v-btn icon small width="35px">
                <v-icon>mdi-table-large</v-icon>
              </v-btn>
              <v-btn icon small width="35px">
                <v-icon>mdi-view-agenda</v-icon>
              </v-btn>
              <v-btn icon small width="35px">
                <v-icon>mdi-view-comfy</v-icon>
              </v-btn>
            </v-btn-toggle>
            {{ $t("Schimbă modul de vizualizare al înregistrărilor.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-table-column</v-icon>
            </v-btn>
            {{ $t("Deschide/închide formularul pentru modificarea coloanelor care se afișează în tabel.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-export-variant</v-icon>
            </v-btn>
            {{ $t("Face exportul datelor din tabel în fișier CSV.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            {{ $t("Deschide o ferestră de unde se poate modifica înregistrarea.") }}
          </div>
          <div>
            <v-btn color="primary" fab text small>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            {{ $t("Deschide o ferestră pentru vizualizarea înregistrări în format marc.") }}
          </div>

          <v-subheader>{{ $t("Scurtături") }}</v-subheader>
          <div>{{ $t("F5: Reîncarcă datele din tabel.") }}</div>
          <div>{{ $t("F7: Adaugă înregistrare nouă.") }}</div>
          <div>{{ $t("F8: Resetează filtre.") }}</div>
          <div>{{ $t("CTRL+F: Focus pe câmpul căuta.") }}</div>
          <div>{{ $t("CTRL+Home: Merge la prima pagină.") }}</div>
          <div>{{ $t("CTRL+End: Merge la ultima pagină.") }}</div>
          <div>{{ $t("CTRL+PageUp: Merge la pagina anterioară.") }}</div>
          <div>{{ $t("CTRL+PageDown: Merge la următoarea pagină.") }}</div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu
      :close-on-content-click="false"
      bottom
      content-class="v-settings"
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ on }">
        <v-btn text fab small v-on="on">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card class="text-center mb-0" width="300">
        <v-card-text>
          <strong class="mb-3 d-inline-block">{{ $t("Culoare") }}</strong>
          <v-item-group v-model="color">
            <v-item v-for="clr in colors" :key="clr" :value="clr">
              <template #default="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="clr"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>
          <!-- <v-divider class="my-4 secondary" />
          <strong class="mb-3 d-inline-block">{{ $t("Culoare meniu") }}</strong>
          <v-item-group v-model="scrim">
            <v-item v-for="scrm in scrims" :key="scrm" :value="scrm" class="mx-1">
              <template #default="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrm"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group> -->
          <v-divider class="my-4 secondary" />
          <v-row align="center" no-gutters>
            <v-col cols="auto">
              {{ $t("Mod întunecat") }}
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-switch v-model="darkMode" class="ma-0 pa-0" color="primary" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template #activator="{ attrs, on }">
        <v-btn min-width="0" text fab small v-bind="attrs" v-on="on">
          <v-badge v-show="notifications.length > 0" color="red" overlap bordered>
            <template #badge>
              <span>{{ notifications.length }}</span>
            </template>
          </v-badge>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn min-width="0" text fab small @click="reloadApp" v-on="on">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Reîncarcă aplicație") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn min-width="0" text fab small @click="openInNew" v-on="on">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("Deschide în ferestră nouă") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn min-width="0" text fab small @click="fullScreen" v-on="on">
          <v-icon v-if="isFullScreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>
      </template>
      <span v-if="isFullScreen">{{ $t("Ieșire ecran complet") }}</span>
      <span v-else>{{ $t("Ecran complet") }}</span>
    </v-tooltip>
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template #activator="{ attrs, on }">
        <v-btn min-width="0" text fab small v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" flat>
        <v-list-item @click.prevent="doAction('profile')">
          <v-list-item-title>
            {{ $t("Contul meu") + " (" + accountUser + ")" }}
          </v-list-item-title>
        </v-list-item>
        <v-divider class="mb-2 mt-2" />
        <v-list-item @click.prevent="doAction('logout')">
          <v-list-item-title v-text="$t('Deconectare')" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { VHover, VListItem } from "vuetify/lib"
import { mapState, mapMutations } from "vuex"
export default {
  name: "DashboardCoreAppBar",
  components: {
    // DashboardCoreSettings: () => import("./Settings"),
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              )
            }
          }
        })
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notifications: [],
    isFullScreen: false,
    colors: ["#9C27b0", "#00CAE3", "#4CAF50", "#ff9800", "#E91E63", "#FF5252"],
    scrims: [
      "rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)",
      "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
      "rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)"
    ]
  }),
  computed: {
    ...mapState(["drawer"]),
    appType() {
      return this.$store.getters.appType
    },
    isDatabases() {
      if (this.$route.name) {
        if (this.$route.name.indexOf("Databases") == 0) {
          return true
        }
      }
      return false
    },
    menuMaxHeight() {
      return window.innerHeight - 120
    },
    accountUser() {
      return this.$store.getters.accountUser
    },
    color: {
      get() {
        if (this.darkMode) {
          return this.$store.getters.primaryColorDark
        } else {
          return this.$store.getters.primaryColor
        }
      },
      set(val) {
        if (this.darkMode) {
          this.$vuetify.theme.themes.dark.primary = val
          this.$store.commit("primaryColorDark", val)
        } else {
          this.$vuetify.theme.themes.light.primary = val
          this.$store.commit("primaryColor", val)
        }
      }
    },
    darkMode: {
      get() {
        return this.$vuetify.theme.dark
      },
      set(val) {
        this.$vuetify.theme.dark = val
        this.$store.commit("darkMode", val)
      }
    },
    scrim: {
      get() {
        return this.$store.getters.barColor
      },
      set(val) {
        this.$store.commit("SET_SCRIM", val)
      }
    },
    biblioteca() {
      return this.$store.getters.libraryData.library.public_name
    }
  },
  created() {
    this.$log("{{ $route }}", this.$route)
    window.addEventListener("resize", e => {
      setTimeout(() => {
        if (document.fullscreenElement || (window.innerWidth == screen.width && window.innerHeight == screen.height)) {
          this.isFullScreen = true
        } else {
          this.isFullScreen = false
        }
      }, 500)
    })
  },
  methods: {
    reloadApp() {
      this.$store
        .dispatch("login", { username: this.$store.getters.accountUser, password: "pass", reload: true })
        .then(() => {
          this.$router.go(0)
        })
    },

    openInNew() {
      window.open(
        window.location.href,
        this.biblioteca,
        "fullscreen=yes,toolbar=no,scrollbars=no,addressbar=no,top=0,left=0,width=" +
          window.outerWidth +
          ",height=" +
          window.outerHeight
      )
    },
    fullScreen() {
      if (this.isFullScreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen().then(() => {
            this.isFullScreen = false
          })
        } else {
          this.isFullScreen = false
        }
      } else {
        document
          .querySelector("#app")
          .requestFullscreen()
          .then(() => {
            this.isFullScreen = true
          })
      }
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    changeLang(e) {
      //this.$log("change lang ", e)
      this.$i18n.locale = e
      this.$store.commit("language", e)
    },
    doAction(action) {
      this.$log("action2 ", action)
      switch (action) {
        case "profile":
          this.$router.push({ path: "/contul-meu" })
          break
        case "settings":
          break
        case "logout":
          this.$store.dispatch("logOut")
          break
      }
    }
  }
}
</script>
